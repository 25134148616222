import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
let redirected = false

export default function FraudPage({data,path}){
  let paths = data.allMarkdownRemark.edges.map(e => e.node.fields.path)
  if(typeof window !== "undefined" && !redirected){
    navigate("/"+paths[Math.floor(Math.random()*paths.length)])
    redirected = true
  }
  return (
      <div></div>
  )
}



export const query = graphql`
query GetFraudPages {
  allMarkdownRemark(filter: {fields: {table: {eq: "fraud"}}}) {
    edges {
      node {
        id
        fields {
          path
          slug
        }
      }
    }
  }
}
`
